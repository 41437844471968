<template>
  <div>
    <a
      v-if="isGuacDital"
      href="https://guacdigital.com"
      target="_blank"
      :class="['vidjet-redirect-link', { isTopLeft }, { isBubbleLeft }]"
      ><img
        src="@/assets/guac-logo.png"
        class="vidjet-logo"
        alt="guac-digital-logo"
    /></a>
    <a
      v-else
      :href="toVidjet"
      target="_blank"
      rel="nofollow"
      :class="['vidjet-redirect-link', { isTopLeft }, { isBubbleLeft }]"
      @click="sendEventClick()"
    >
      <img
        src="@/assets/vidjet-logo.png"
        class="vidjet-logo"
        alt="Vidjet logo"
      />
    </a>
  </div>
</template>

<script>
import enums from "@/enums.js";
const { Position } = enums;
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("playerCampaign", [
      "format",
      "isEmbed",
      "isBubble",
      "isFullScreen",
      "isCarousel",
      "position",
    ]),
    ...mapGetters("videos", ["isMultiple"]),
    ...mapGetters("analytics", ["getUrl"]),
    ...mapGetters("playerSite", ["site"]),

    //Check if we need to display the watermark on the right side of the bubbule video
    isBubbleLeft() {
      if (this.isBubble && !this.isTopLeft) {
        return this.position === Position.left;
      }

      return false;
    },

    isTopLeft() {
      return (
        this.isCarousel ||
        this.isFullScreen ||
        this.isEmbed ||
        (this.isBubble && this.isMultiple)
      );
    },

    toVidjet() {
      let url = this.getUrl;
      url ??= document.referrer;
      const campaignId = this.$route.params.campaignId;
      return `https://vidjet.io?utm_source=vidjet_user_campaign&utm_campaign=${campaignId}&utm_medium=${url}`;
    },
    isGuacDital() {
      return this.site._id === "32659eed-c872-41b6-a7b8-2bb240dfadeb";
    },
  },
  methods: {
    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),
    sendEventClick() {
      this.sendEvent({ type: "watermarkClicked" });
    },
  },
};
</script>

<style lang="scss" scoped>
$link-height: 50px;
$logo-height: 10px;
$logo-width: 100px;

.vidjet-redirect-link {
  height: $link-height;
  background: rgba(88, 88, 88, 0.8);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -20px;
  z-index: -1;
}

.isTopLeft.vidjet-redirect-link {
  z-index: 25;
  top: 16px;
  left: 16px;
  height: fit-content;
}

.isBubbleLeft.vidjet-redirect-link {
  right: 0;
}

.vidjet-logo {
  height: $logo-height;
  width: $logo-width;
  cursor: pointer;
  margin-top: auto;
  padding: 5px 10px;
}
</style>
